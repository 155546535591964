import React from 'react';

const UsesPage = () => {
  return <>
    <div className="UsesPage">
      <span>
        Welcome to my <a href="https://uses.tech/" target="_blank" rel="noreferrer">uses</a> page! It's... a WIP.
      </span>
    </div>
  </>;
};

export default UsesPage;
